@font-face {
    font-family: "Vga";
    src: url("../fonts/vga.woff") format("woff"),
        url("../fonts/vga.woff2") format("woff2"),
        url("../fonts/vga.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

// TODO: add theme mixins
$font-family: "Vga", Menlo, Monaco, Consolas, "Courier New", monospace;

$scalefactor: 2.0;
// the following values are font-dependant

$fontsize: 24px * $scalefactor;
$lineheight: 19px * $scalefactor;