// default: black bg, blue/white fg

// TODO: add theme mixins
// blue/white
$foreground: rgb(212, 249, 250);
$background: rgb(0, 12, 12);
$alert: rgb(255, 60, 0);

// amber
// $foreground: rgb(224, 125, 11);
// $background: rgb(8, 4, 0);
// $alert: rgb(255, 60, 0);

// green
// $foreground: rgb(36, 161, 20);
// $background: rgb(0, 2, 0);
// $alert: rgb(255, 60, 0);

// white
// $foreground: rgb(218, 218, 218);
// $background: rgb(2, 2, 2);
// $alert: rgb(255, 60, 0);

$scanlines-foreground: rgba($foreground, 0.1);
$scanlines-background: rgba($background, 0.5);

$foreground-glow: 0 0 5px rgba($foreground, 0.5);
$background-glow: 0 0 2px rgba($background, 0.5);
$alert-glow: 0 0 5px rgba($alert, 0.5);

$background-gradient: radial-gradient(rgba($foreground, 0.15), rgba($background, 1) 100%)