@import "../../assets/sass/fonts";
@import "../../assets/sass/colors";

html, body {
    font-family: $font-family;
    font-size: $fontsize;
    line-height: $lineheight;
    color: $foreground;

    user-select: none;
    text-shadow: $foreground-glow;

    height: 100%;
    margin: 0;
}

body {
    margin: 0;
    background: $background;
    background-image: $background-gradient;
    background-attachment: fixed;
    height: 100%;
}

.phosphor {
    padding: $lineheight;
}

.alert {
    color: $alert;
    text-shadow: $alert-glow;

    & > span.cursor {
        color: $alert;
        background-color: rgba($alert, 0.75);
    }
}

.center {
    text-align: center;
}

.centerPage {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 19px;
    transform: translate(-50%, -50%);    
}

.topRight {
    position:absolute;
    top: 5%;
    right: 3%;
}
.topRightUnder {
    position:absolute;
    margin-top: 50px;
    top: 5%;
    right: 3%;
}

.red > div, .red > div > p{
    background-color: darkred !important;
    color: white;
}